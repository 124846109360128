<template>
  <div
    class="min-vh-100 min-vw-100 d-flex align-items-center overflow-hidden bg-light"
    style="overflow: hidden; top: 0; margin: 0; position: absolute"
  >
    <div class="account-pages w-100 h-100">
      <div class="login-container">
        <div class="login-img"></div>

        <div class="login-form">
          <div class="card">
            <div class="card-body p-4">
              <div class="text-center mt-2">
                <h4>{{ $t('login.welcome') }}</h4>
              </div>
              <div class="p-2 mt-4">
                <b-alert
                  v-model="isAuthError"
                  variant="danger"
                  class="mt-3"
                  dismissible
                  >{{ authError }}</b-alert
                >

                <!-- <div
                    v-if="notification.message"aa
                    :class="'alert ' + notification.type"
                  >
                    {{ notification.message }}
                  </div> -->

                <b-form @submit.prevent="tryToLogIn">
                  <b-form-group
                    id="input-group-1"
                    class="mb-3"
                    :label="$t('login.userName')"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      v-model="userName"
                      type="text"
                      :placeholder="$t('login.userName')"
                      :class="{
                        'is-invalid': submitted && $v.userName.$error,
                      }"
                    ></b-form-input>
                    <div
                      v-if="submitted && $v.userName.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.userName.required"
                        >Nombre de usuario es requerido.</span
                      >
                      <span v-if="!$v.userName.maxLength">
                        16 caracteres maximos</span
                      >
                      <span v-if="!$v.userName.alphaNum">
                        Solo Valores alfanumericos</span
                      >
                    </div>
                  </b-form-group>

                  <b-form-group id="input-group-2" class="mb-3">
                    <label for="input-2">{{ $t('login.password') }}</label>
                    <b-form-input
                      id="input-2"
                      v-model="password"
                      type="password"
                      :placeholder="$t('login.password')"
                      :class="{
                        'is-invalid': submitted && $v.password.$error,
                      }"
                    ></b-form-input>
                    <div
                      v-if="submitted && $v.password.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.password.required"
                        >La contraseña es requeridad.</span
                      >
                      <span v-if="!$v.password.maxLength">
                        16 caracteres maximos</span
                      >
                    </div>
                  </b-form-group>
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="auth-remember-check"
                    />
                    <label class="form-check-label" for="auth-remember-check">{{
                      $t('login.rememberMe')
                    }}</label>
                  </div>
                  <div class="resetPassword mt-2 d-flex">
                    <router-link to="/forgot-password">{{
                      $t('login.forgotPassword')
                    }}</router-link>
                  </div>
                  <div class="mt-3 d-flex justify-content-end">
                    <vs-button
                      type="submit"
                      success
                      class="w-sm"
                      :loading="loading"
                    >
                      {{ $t('login.login') }}
                    </vs-button>
                  </div>
                </b-form>
              </div>
              <!-- end card-body -->
            </div>
            <!-- end card -->
          </div>
          <div class="mt-5 text-center">
            <p class="d-flex align-items-center justify-content-center">
              {{ new Date().getFullYear() }}

              <img
                src="@/assets/images/SPRINT_GAMING_PNG.png"
                alt
                class="img-fluid d-inline-block footer-img"
              />

              &copy; copyright
            </p>
          </div>
          <!-- end row -->
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
  </div>
</template>

<script>
import { required, maxLength, alphaNum } from 'vuelidate/lib/validators';
import appConfig from '@/app.config';
import Swal from 'sweetalert2';
import { mapActions, mapGetters, mapMutations } from 'vuex';

/**
 * Login component
 */
export default {
  page: {
    title: 'Login',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      userName: null,
      password: null,
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      token: '',
      userValid: false,
      loading: false,
    };
  },
  validations: {
    userName: {
      required,
      maxLength: maxLength(50),
      alphaNum,
    },
    password: {
      required,
      maxLength: maxLength(16),
    },
  },
  computed: {
    notification() {
      return this.$state ? this.$state.state.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$state && this.$state.state.notification ? 5 : 0;
    },
    ...mapGetters({
      success: 'auth/isSuccess',
      getToken: 'auth/getToken',
    }),
  },
  methods: {
    // Try to log the user in with the username
    // and password they provided.
    async tryToLogIn() {
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) return;

      this.tryingToLogIn = true;
      // Reset the authError if it existed.
      this.authError = null;

      this.loading = true;

      try {
        await Promise.all([this.login()]);
        await this.getUserData();

        if (!this.userValid) throw new Error('Error autenticando');

        this.$router.push(
          this.$route.query.redirectFrom || {
            path: '/',
          }
        );
      } catch (err) {
        console.log('ERROR TRY TO LOGIN', err);
        this.tryingToLogIn = false;
        this.authError = 'ERROR AUTENTICANDO';
        this.isAuthError = true;
      }
      this.loading = false;
    },
    async login() {
      await this.loginUser({
        userName: this.userName,
        password: this.password,
      });

      if (!this.success) {
        this.userValid = false;
        Swal.fire({
          title: 'Datos incorrectos',
          text: 'Intente nuevamente',
          icon: 'error',
        });
        throw new Error('Error autenticando');
      }

      this.tryingToLogIn = false;
      this.isAuthError = false;
      this.userValid = true;

      this.token = this.getToken;
    },
    async getUserData() {
      try {
        const body = {
          token: this.token,
        };
        await this.fetchUser({ body });
        this.updateTokenWheel(this.token);
        this.userValid = true;
      } catch (error) {
        console.log('ERROR GET USER DATA', error);
        this.userValid = false;
      }
    },

    ...mapActions({
      fetchUser: 'user/fetchUserData',
      loginUser: 'auth/login',
    }),
    ...mapMutations({
      updateTokenWheel: 'user/updateTokenWheel',
    }),
  },
};
</script>

<style scoped>
.login-container {
  display: grid;
  grid-template-columns: 70% 30%;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.login-form {
  width: 90%;
}

.login-img {
  background: url('../../../assets/images/img-left.png');
  background-position: center;
  display: block;
  height: 100vh;
  max-height: 900px;
  width: 100%;
  /* height: auto; */
  /* object-fit: contain; */
  background-size: contain;
  background-position-x: 70px;
  background-position-y: bottom;
  background-repeat: no-repeat;
  position: relative;
  bottom: 0;
  align-self: flex-end;
}

@media (max-width: 600px) {
  .login-container {
    grid-template-columns: 100%;
    height: 100vh;
  }

  .login-img {
    top: 0;
    position: absolute;
  }

  .login-form {
    justify-self: center;
  }

  .card {
    filter: #f8f9fae5 !important;
    backdrop-filter: blur(1.2px);
  }
}

.bg-soft-secondary .bg-light {
  background: url('../../../assets/images/bg.jpg');
  background-position: center;
  display: block;
  height: 100vh;
  background-size: cover;
}

.footer-img {
  width: 80px;
  height: auto;
  object-fit: contain;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #f8f9fab0;
  background-clip: border-box;
  border: 0 solid #f6f6f6;
  border-radius: 0.25rem;
  color: #9e1192;
}

.btn-primary {
  color: #fff;
  background-color: #9e1192;
  border-color: #9e1192;
}

h4 {
  color: #9e1192;
  text-transform: uppercase;
  font-weight: 800;
}

.form-control:nth-child(1) {
  background: url('../../../assets/images/usuario.png');
  background-position: left;
  background-repeat: no-repeat;
  padding-left: 9%;
  background-size: 7%;
}

.form-control:nth-child(2) {
  background: url('../../../assets/images/candado.png');
  background-position: left;
  background-repeat: no-repeat;
  padding-left: 9%;
  background-size: 7%;
}

label {
  color: #9e1192 !important;
}

.align-items-center {
  color: white;
}
</style>
